import React from 'react';

const icons = require.context('../assets/icons/', true);
const iconPath = (name) => icons(name, true);

export default function ServiceItem(service) {
	return (
		<div className="mlg_service-item">
			<div className="mlg_si-header">
				<div className="mlg_si-icon">
					<img src={iconPath('./' + service.icon.src)} alt={service.icon.alt} />
				</div>

				<h3 className="mlg_si-title">
					<a href={service.link}>{service.title}</a>
				</h3>
			</div>

			<div className="mlg_si-desc">
				<p dangerouslySetInnerHTML={{ __html: service.description }} />
			</div>
		</div>
	);
}
