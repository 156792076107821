import { Link } from 'gatsby';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Inquiry from './inquiry';

const organizationDataText = (organization) => {
	switch (organization) {
		case 'Kommune': {
			return 'Einwohner';
		}
		case 'Unternehmen': {
			return 'Mitarbeiter';
		}
		case 'Verein': {
			return 'Mitglieder';
		}
	}
};

export default function PriceCalculator(props) {
	const [appBetreuung, setAppBetreuung] = React.useState(false);
	const [organization, setOrganization] = React.useState(props.defaultValue);
	const [respondents, setRespondents] = React.useState('23000');

	const prices = React.useMemo(() => {
		const num = parseInt(respondents);
		if (num < 5000) {
			return {
				setupCost: 5000,
				licenseCost: 2500,
				perElectionCost: 2000,
			};
		} else if (num < 25000) {
			return {
				setupCost: 6000,
				licenseCost: 5000,
				perElectionCost: 3000,
			};
		} else if (num < 50000) {
			return {
				setupCost: 7000,
				licenseCost: 10000,
				perElectionCost: 4000,
			};
		} else if (num < 75000) {
			return {
				setupCost: 8000,
				licenseCost: 15000,
				perElectionCost: 5000,
			};
		} else if (num < 100000) {
			return {
				setupCost: 9000,
				licenseCost: 20000,
				perElectionCost: 6000,
			};
		} else {
			return {
				setupCost: 10000,
				licenseCost: null,
				perElectionCost: null,
			};
		}
	}, [respondents]);

	const ref = React.useRef(null);
	const handleButtonClick = React.useCallback(() => {
		ref.current.openModal();
	}, []);

	const handlePackage1Click = React.useCallback(() => {
		setAppBetreuung(false);

		document.querySelector('#pc-package2').classList.remove('active');
		document.querySelector('#pc-package1').classList.add('active');
	}, []);

	const handlePackage2Click = React.useCallback(() => {
		setAppBetreuung(true);

		document.querySelector('#pc-package1').classList.remove('active');
		document.querySelector('#pc-package2').classList.add('active');
	}, []);

	const desktopPriceCalculator = React.useMemo(() => {
		return (
			<Container>
				<Row>
					<Col xl={1}></Col>
					<Col md={12} xl={11}>
						<div className="text-md-left mlg_pc-price-label">
							<h4>Ihr Preis</h4>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl={1}></Col>

					<Col md={3} xl={2}>
						<p className="mlg_pc-price-desc" style={prices.licenseCost ? {} : { height: '118px' }}>
							<span>
								einmalige
								<br />
							</span>
							Einrichtungskosten
						</p>
						<p className="mlg_pc-price-desc" style={prices.licenseCost ? {} : { height: '118px' }}>
							Lizenzkosten
							<br />
							pro Jahr
						</p>
						<p
							className="mlg_pc-price-desc"
							style={prices.licenseCost ? { lineHeight: '72px' } : { height: '118px' }}>
							pro Befragung
						</p>
					</Col>

					<Col md={4} xl={3}>
						<p className="mlg_pc-price" style={prices.licenseCost ? {} : { height: '118px' }}>
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
								prices.setupCost
							)}
						</p>
						<p
							className="mlg_pc-price"
							style={prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.licenseCost
								  )
								: 'Preise auf Anfrage'}
						</p>
						<p
							className="mlg_pc-price"
							style={prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{prices.perElectionCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</p>
					</Col>

					<Col md={1} xl={2}></Col>

					<Col md={4} xl={3} className="mlg_pc-vertical-ruler-total">
						<div className="mlg_pc-total">
							<p className="mlg_pc-total-title">Betrag im 1. Jahr</p>
							<p className="mlg_pc-total-price">
								{prices.licenseCost
									? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
											prices.setupCost + prices.licenseCost + prices.perElectionCost
									  )
									: 'Preise auf Anfrage'}
							</p>
							<div className="mlg_btn mlg_b-white mlg_pc-btn">
								<a href="#" onClick={handleButtonClick}>
									Anfrage&nbsp;stellen
								</a>
							</div>
						</div>
					</Col>

					<Col xl={1}></Col>
				</Row>
			</Container>
		);
	}, [respondents]);

	const tabletPriceCalculator = React.useMemo(() => {
		return (
			<Container>
				<Row>
					<Col sm={12}>
						<div className="text-md-left mlg_pc-price-label">
							<h4>Ihr Preis</h4>
						</div>
					</Col>
				</Row>

				<Row>
					<Col sm={6}>
						<p className="mlg_pc-price-desc" style={prices.licenseCost ? {} : { height: '118px' }}>
							<span>
								einmalige
								<br />
							</span>
							Einrichtungskosten
						</p>
						<p className="mlg_pc-price-desc" style={prices.licenseCost ? {} : { height: '118px' }}>
							Lizenzkosten
							<br />
							pro Jahr
						</p>
						<p
							className="mlg_pc-price-desc"
							style={prices.licenseCost ? { lineHeight: '72px' } : { height: '118px' }}>
							pro Befragung
						</p>
					</Col>
					<Col sm={6}>
						<p className="mlg_pc-price" style={prices.licenseCost ? {} : { height: '118px' }}>
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
								prices.setupCost
							)}
						</p>
						<p
							className="mlg_pc-price"
							style={prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.licenseCost
								  )
								: 'Preise auf Anfrage'}
						</p>
						<p
							className="mlg_pc-price"
							style={prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{prices.perElectionCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</p>
					</Col>
				</Row>

				<Row>
					<Col sm={12}>
						<hr className="mlg_pc-horizontal-ruler" />
					</Col>
				</Row>

				<Row>
					<Col sm={6}>
						<p className="mlg_pc-total-title">Betrag im 1.Jahr</p>
					</Col>
					<Col sm={6}>
						<p className="mlg_pc-total-price">
							{prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.setupCost + prices.licenseCost + prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</p>
					</Col>
				</Row>

				<Row style={{ marginTop: '75px' }}>
					<Col sm={12}>
						<div className="mlg_btn mlg_b-white mlg_pc-btn">
							<a href="#" onClick={handleButtonClick}>
								Anfrage stellen
							</a>
						</div>
					</Col>
				</Row>
			</Container>
		);
	}, [respondents]);

	const mobilePriceCalculator = React.useMemo(() => {
		return (
			<Container>
				<div className="text-md-left mlg_pc-price-label">
					<h4>Ihr Preis</h4>
				</div>
				<div className="mlg_pc-price-container">
					<div>
						<div className="mlg_pc-price">
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
								prices.setupCost
							)}
						</div>
						<p className="mlg_pc-price-desc">
							<span>
								einmalige
								<br />
							</span>
							Einrichtungskosten
						</p>
					</div>

					<span className="mlg_pc-plus">+</span>

					<div>
						<div className="mlg_pc-price">
							{prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.licenseCost
								  )
								: 'Preise auf Anfrage'}
						</div>
						<p className="mlg_pc-price-desc">
							Lizenzkosten
							<br />
							pro Jahr
						</p>
					</div>

					<span className="mlg_pc-plus">+</span>

					<div>
						<div className="mlg_pc-price">
							{prices.perElectionCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</div>
						<p className="mlg_pc-price-desc">pro Befragung</p>
					</div>

					<hr className="mlg_pc-horizontal-ruler" />

					<p className="mlg_pc-total-price">
						{prices.licenseCost
							? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
									prices.setupCost + prices.licenseCost + prices.perElectionCost
							  )
							: 'Preise auf Anfrage'}
					</p>
					<p className="mlg_pc-total-title">Betrag im 1. Jahr</p>

					<div className="mlg_btn mlg_b-block mlg_b-white mlg_pc-btn">
						<a href="#" onClick={handleButtonClick}>
							Anfrage stellen
						</a>
					</div>
				</div>
			</Container>
		);
	}, [respondents]);

	return (
		<section className="mlg_section mlg_price-calculator-bg">
			<div id="preise" className="mlg_price-calculator mb-6rem">
				<Container>
					<Row>
						<Col xs={12}>
							<div className="mlg_heading mlg_h-inline">
								<h2 style={{ fontSize: '50px', lineHeight: '65px' }}>Preisrechner</h2>
								<p className="pr-xs-30px">
									Individuelle Preise für jeden Zweck. Sprechen Sie uns jederzeit an!
								</p>
							</div>
						</Col>
					</Row>

					<Row className="mlg_pc-header align-items-center align-items-md-start">
						<Col lg={2} md={6} className="mb-sm-14px mb-xs-14px">
							<strong>Wir sind ein/eine</strong>
						</Col>

						<Col lg={3} md={6} className="mb-md-25px mb-sm-48px mb-xs-48px">
							<label htmlFor="organization">Art der Organisation</label>
							<select
								id="organization"
								name="organization"
								className="mlg_pc-field"
								value={organization}
								onChange={(e) => setOrganization(e.target.value)}>
								<option value="Kommune">Kommune</option>
								<option value="Unternehmen">Unternehmen</option>
								<option value="Verein">Verein</option>
							</select>
						</Col>

						<Col lg={2} md={6} className="text-lg-center mb-sm-14px mb-xs-14px">
							<strong>und möchten</strong>
						</Col>

						<Col lg={3} md={6} className="mb-md-25px mb-sm-48px mb-xs-48px">
							<label htmlFor="respondents">Anzahl der Befragten</label>
							<input
								id="respondents"
								name="respondents"
								className="mlg_pc-field"
								type="number"
								step="1000"
								min="1000"
								max="500000"
								pattern="[0-9]+([\.,][0-9]+)?"
								onChange={(e) => setRespondents(e.target.value)}
								value={respondents}
							/>
						</Col>

						<Col lg={2} md={12} className="text-lg-right">
							<strong>
								<span className="mlg_pc-field-trigger">{organizationDataText(organization)}</span>{' '}
								befragen.
							</strong>
						</Col>
					</Row>
				</Container>

				<div className="mlg_pc-footer mlg_bg-gradient-2" id="mlg_pc-calc-desktop">
					{desktopPriceCalculator}
				</div>
				<Container>
					<Row className="mlg_pc-packages">
						<Col lg={4} md={12} id="package-first-col">
							<h6 className="mb-2-5rem mb-sm-0px mb-xs-0px">
								Wir bieten eine Kombination aus vielen verschiedenen Service-Leistungen an.
							</h6>

							<p className="color-green">
								<strong>Sie können sich nicht entscheiden?</strong>
								<br />
								<Link to="/#service">
									<img
										src={require('../assets/icons/link-arrow-icon-blue.svg')}
										style={{ marginRight: '8px' }}
										alt=""
									/>
									Jetzt unsere Service-Leistungen entdecken
								</Link>
							</p>
						</Col>

						<Col lg={4} md={6}>
							<div className="mlg_pc-package active" id="pc-package1" onClick={handlePackage1Click}>
								<h4 className="mlg_pc-p-title">Standard App</h4>

								<button className="mlg_pc-p-btn" type="button" onClick={handlePackage1Click}>
									Wählen
								</button>
							</div>
						</Col>

						<Col lg={4} md={6}>
							<div className="mlg_pc-package" id="pc-package2" onClick={handlePackage2Click}>
								<h4 className="mlg_pc-p-title">App & Betreuung</h4>
								<p className="mlg_pc-p-description">
									Die optionalen Service-Pakete
									<br />
									werden individuell berechnet.
								</p>

								<button className="mlg_pc-p-btn" type="button" onClick={handlePackage2Click}>
									Wählen
								</button>
							</div>
						</Col>
					</Row>
				</Container>

				<div className="mlg_pc-footer mlg_bg-gradient-2" id="mlg_pc-calc-mobile">
					{mobilePriceCalculator}
				</div>
				<div className="mlg_pc-footer mlg_bg-gradient-2" id="mlg_pc-calc-tablet">
					{tabletPriceCalculator}
				</div>
			</div>
			<Inquiry
				prices={prices}
				appBetreuung={appBetreuung}
				respondents={respondents}
				organization={organization}
				ref={ref}
			/>
		</section>
	);
}
