import React from 'react';
import { Alert, Col, Container, Modal, Row } from 'react-bootstrap';
import content from '../data/inquiry.json';
import { Controller, useForm } from 'react-hook-form';
import ContactMessage from '../types/ContactMessage';
import PriceRequest from '../types/priceRequest';
import service, { createService } from '../service/service';
import { AlertType, alertMessage } from '../components/contact-form';

function Inquiry(props, ref) {
	const [open, setOpen] = React.useState(false);
	const [alertData, setAlertData] = React.useState({ open: false, type: AlertType.info, message: '' });

	const openModal = React.useCallback(() => {
		setOpen(true);
	}, []);

	React.useEffect(() => {
		if (alertData.open) {
			setTimeout(() => {
				setAlertData({ open: false, type: AlertType.info, message: '' });
			}, 4000);
		}
	}, [alertData.open]);

	const { control, watch, handleSubmit, reset } = useForm();

	const onSubmit = React.useCallback(
		(values) => {
			const message = new ContactMessage(values.name, values.email, values.message, undefined, values.company);
			const requestData = new PriceRequest(
				message,
				props.appBetreuung ? 'App & Betreuung' : 'White Label App',
				props.organization,
				parseInt(props.respondents)
			);

			service.setAdapter(createService());
			service
				.priceRequest(requestData)
				.then(() => {
					setAlertData({ open: true, type: AlertType.success, message: alertMessage.success });
					reset();
				})
				.catch(() => setAlertData({ open: true, type: AlertType.error, message: alertMessage.error }));
		},
		[props.appBetreuung, props.organization, props.respondents]
	);

	const closeModal = React.useCallback(() => {
		setOpen(false);
	}, []);

	React.useImperativeHandle(ref, () => {
		return {
			openModal: openModal,
		};
	});

	const values = watch();

	React.useEffect(() => {
		Object.entries(values).forEach((value) => {
			const input = document.querySelector(`.inquiry-modal .mlg_contact-form #${value[0]}.mlg_cf-field`);
			if (value[1] === '') {
				input.classList.remove('focused');
				input.parentNode.classList.remove('focused');
			} else {
				input.classList.add('focused');
				input.parentNode.classList.add('focused');
			}
		});
	}, [values]);

	const desktopPriceCalculator = React.useMemo(() => {
		return (
			<Container id="inquiry-calculator-desktop">
				<Row>
					<Col xl={1}></Col>
					<Col xl={11}>
						<div className="text-md-left mlg_pc-price-label">
							<h4>Ihr Preis</h4>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl={1}></Col>

					<Col xl={2}>
						<p className="mlg_pc-price-desc" style={props.prices.licenseCost ? {} : { height: '118px' }}>
							<span>
								einmalige
								<br />
							</span>
							Einrichtungskosten
						</p>
						<p className="mlg_pc-price-desc" style={props.prices.licenseCost ? {} : { height: '118px' }}>
							Lizenzkosten
							<br />
							pro Jahr
						</p>
						<p
							className="mlg_pc-price-desc"
							style={props.prices.licenseCost ? { lineHeight: '72px' } : { height: '118px' }}>
							pro Befragung
						</p>
					</Col>

					<Col xl={3}>
						<p className="mlg_pc-price" style={props.prices.licenseCost ? {} : { height: '118px' }}>
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
								props.prices.setupCost
							)}
						</p>
						<p
							className="mlg_pc-price"
							style={props.prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{props.prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.licenseCost
								  )
								: 'Preise auf Anfrage'}
						</p>
						<p
							className="mlg_pc-price"
							style={props.prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{props.prices.perElectionCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</p>
					</Col>

					<Col xl={1}></Col>
					<Col xl={1} className="mlg_pc-vertical-ruler"></Col>

					<Col xl={3} className="mlg_pc-vertical-ruler-total">
						<div className="mlg_pc-total">
							<p className="mlg_pc-total-title">Betrag im 1. Jahr</p>
							<p className="mlg_pc-total-price">
								{props.prices.licenseCost
									? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
											props.prices.setupCost +
												props.prices.licenseCost +
												props.prices.perElectionCost
									  )
									: 'Preise auf Anfrage'}
							</p>
						</div>
					</Col>

					<Col xl={1}></Col>
				</Row>
			</Container>
		);
	}, [props.respondents]);

	const tabletPriceCalculator = React.useMemo(() => {
		return (
			<Container id="inquiry-calculator-tablet">
				<Row>
					<Col sm={11}>
						<div className="text-md-left mlg_pc-price-label">
							<h4>Ihr Preis</h4>
						</div>
					</Col>
				</Row>

				<Row>
					<Col sm={6}>
						<p className="mlg_pc-price-desc" style={props.prices.licenseCost ? {} : { height: '118px' }}>
							<span>
								einmalige
								<br />
							</span>
							Einrichtungskosten
						</p>
						<p className="mlg_pc-price-desc" style={props.prices.licenseCost ? {} : { height: '118px' }}>
							Lizenzkosten
							<br />
							pro Jahr
						</p>
						<p
							className="mlg_pc-price-desc"
							style={props.prices.licenseCost ? { lineHeight: '72px' } : { height: '118px' }}>
							pro Befragung
						</p>
					</Col>
					<Col sm={6}>
						<p className="mlg_pc-price" style={props.prices.licenseCost ? {} : { height: '118px' }}>
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
								props.prices.setupCost
							)}
						</p>
						<p
							className="mlg_pc-price"
							style={props.prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{props.prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.licenseCost
								  )
								: 'Preise auf Anfrage'}
						</p>
						<p
							className="mlg_pc-price"
							style={props.prices.licenseCost ? {} : { height: '118px', lineHeight: '42px' }}>
							<span className="mlg_pc-plus">+</span>
							{props.prices.perElectionCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</p>
					</Col>
				</Row>

				<Row>
					<Col sm={12}>
						<hr className="mlg_pc-horizontal-ruler" />
					</Col>
				</Row>

				<Row>
					<Col sm={6}>
						<p className="mlg_pc-total-title">Betrag im 1. Jahr</p>
					</Col>
					<Col sm={6}>
						<p className="mlg_pc-total-price">
							{props.prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.setupCost + props.prices.licenseCost + props.prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</p>
					</Col>
				</Row>
			</Container>
		);
	}, [props.respondents]);

	const mobilePriceCalculator = React.useMemo(() => {
		return (
			<Container id="inquiry-calculator-mobile">
				<div className="text-md-left mlg_pc-price-label">
					<h4>Ihr Preis</h4>
				</div>
				<div className="mlg_pc-price-container">
					<div>
						<div className="mlg_pc-price">
							{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
								props.prices.setupCost
							)}
						</div>
						<p className="mlg_pc-price-desc">
							<span>
								einmalige
								<br />
							</span>
							Einrichtungskosten
						</p>
					</div>

					<span className="mlg_pc-plus">+</span>

					<div>
						<div className="mlg_pc-price">
							{props.prices.licenseCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.licenseCost
								  )
								: 'Preise auf Anfrage'}
						</div>
						<p className="mlg_pc-price-desc">
							Lizenzkosten
							<br />
							pro Jahr
						</p>
					</div>

					<span className="mlg_pc-plus">+</span>

					<div>
						<div className="mlg_pc-price">
							{props.prices.perElectionCost
								? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
										props.prices.perElectionCost
								  )
								: 'Preise auf Anfrage'}
						</div>
						<p className="mlg_pc-price-desc">pro Befragung</p>
					</div>

					<hr className="mlg_pc-horizontal-ruler" />

					<p className="mlg_pc-total-price">
						{props.prices.licenseCost
							? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
									props.prices.setupCost + props.prices.licenseCost + props.prices.perElectionCost
							  )
							: 'Preise auf Anfrage'}
					</p>
					<p className="mlg_pc-total-title">Betrag im 1. Jahr</p>
				</div>
			</Container>
		);
	}, [props.respondents]);

	return (
		<Modal show={open} onHide={closeModal} size="xl" className="inquiry-modal">
			<Modal.Header closeButton>
				<Modal.Title>
					<h2 className="inquiry-title">{content.title}</h2>
					<p className="inquiry-subtitle">{content.subtitle}</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container>
					<Row className="inquiry-row-info">
						<Col xl={1}></Col>
						<Col xs={12} lg={6} xl={4}>
							<p className="inquiry-description">{content.packet}</p>
							<p className="h4 inquiry-field">
								{props.appBetreuung ? 'App & Betreuung' : 'White Label App'}
							</p>
						</Col>
						<Col xs={12} lg={6} xl={3}>
							<p className="inquiry-description">{content.organization}</p>
							<p className="h4 inquiry-field">{props.organization}</p>
						</Col>
						<Col xs={12} lg={6} xl={3}>
							<p className="inquiry-description">{content.respondents}</p>
							<p className="h4 inquiry-field">{props.respondents}</p>
						</Col>
						<Col xl={1}></Col>
					</Row>
				</Container>
				<div className="mlg_pc-footer mlg_bg-gradient-2">
					{desktopPriceCalculator}
					{mobilePriceCalculator}
					{tabletPriceCalculator}
				</div>
				<Container>
					<Row>
						<Col xl={1}></Col>
						<Col xs={12} xl={5}>
							<h4 className="inquiry-contact-title">{content.contact.title}</h4>
							<form className="mlg_contact-form inquiry_contact-form" onSubmit={handleSubmit(onSubmit)}>
								<div className="mlg_cf-row required">
									<Controller
										as={<input required />}
										type="text"
										id="name"
										name="name"
										className="mlg_cf-field"
										control={control}
										defaultValue=""
										rules={{ required: true, minLength: 2 }}
									/>
									<label htmlFor="name">{content.contact.name}</label>
								</div>

								<div className="mlg_cf-row required">
									<Controller
										as={<input required />}
										type="email"
										id="email"
										name="email"
										className="mlg_cf-field"
										control={control}
										defaultValue=""
										rules={{ required: true }}
									/>
									<label htmlFor="email">{content.contact.email}</label>
								</div>

								<div className="mlg_cf-row">
									<Controller
										as={<input />}
										type="text"
										id="company"
										name="company"
										className="mlg_cf-field"
										control={control}
										defaultValue=""
									/>
									<label htmlFor="company">
										<span id="company-label">{content.contact.organizationPart1}</span>
										{content.contact.organizationPart2}
									</label>
								</div>

								<p className="inquiry-contact-question">{content.contact.question}</p>

								<div className="mlg_cf-row">
									<Controller
										as={<textarea required />}
										id="message"
										name="message"
										rows="1"
										className="mlg_cf-field"
										control={control}
										defaultValue=""
										rules={{ required: true }}
									/>
									<label htmlFor="message">{content.contact.message}</label>
								</div>

								<div className="mlg_cf-footer">
									<div className="mlg_cf-submit">
										<button type="submit">{content.contact.submit}</button>
									</div>
								</div>
							</form>
							<Alert show={alertData.open} variant={alertData.type} style={{ marginTop: '16px' }}>
								{alertData.message}
							</Alert>
						</Col>
						<Col xl={5} id="inquiry-image">
							<img src={require('../assets/img/THNG-message-contact.png')} alt="" />
						</Col>
						<Col xl={1}></Col>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>
	);
}

export default React.forwardRef(Inquiry);
