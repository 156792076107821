import ContactMessage from './ContactMessage';

class PriceRequest {
	public message: ContactMessage;
	public product: string;
	public organizationType: string;
	public maxUser: number;

	constructor(message: ContactMessage, product: string, organizationType: string, maxUser: number) {
		this.message = message;
		this.product = product;
		this.organizationType = organizationType;
		this.maxUser = maxUser;
	}

	public toJSON() {
		return {
			...this.message.toJSON(),
			product: this.product,
			organizationType: this.organizationType,
			maxUser: this.maxUser,
		};
	}
}

export default PriceRequest;
