import React from 'react';
import { Container, Row, Col, Tab, Nav, Accordion } from 'react-bootstrap';
import imageSelector from '../helpers/imageSelector';

const images = require.context('../assets/img/', true);
const imagePath = (name) => images(name, true);

export default function Solutions(data) {
	const [image, setImage] = React.useState({ src: 'Anmeldung-Screen.png', alt: 'Anmeldung' });

	const tabs = React.useMemo(() => {
		return (
			<div className="mlg_tabs">
				<Tab.Container defaultActiveKey={data.tabs[0].id}>
					<Nav bsPrefix="mlg_t-nav" as="ul" className="mlg_t-custom-12345" style={{ maxWidth: '100%' }}>
						{data.tabs.map((element, i) => {
							return (
								<li key={i}>
									<Nav.Link
										bsPrefix="mlg_t-n-item"
										eventKey={element.id}
										className={i === 1 ? 'mlg_t-n-i-color-secondary' : ''}
										onClick={() => {
											const element = document
												.querySelectorAll(
													'.mlg_solutions .mlg_tabs .mlg_t-content .mlg_a-item.active'
												)
												[i].querySelector('span').innerHTML;
											setImage(imageSelector(i, element));
										}}>
										{element.title}
									</Nav.Link>
								</li>
							);
						})}
					</Nav>
					<Tab.Content className="mlg_t-content">
						{data.tabs.map((element, i) => {
							return (
								<Tab.Pane eventKey={element.id} className="mlg_t-pane" key={i}>
									<Accordion
										defaultActiveKey={element.items[0].id}
										className={
											i === 0
												? 'mlg_accordion-numbers'
												: 'mlg_accordion-numbers mlg_a-color-secondary'
										}
										onClick={(event) => {
											const item = event.target.parentNode.parentNode;
											item.parentNode.querySelector('.active').classList.remove('active');
											item.classList.add('active');
										}}>
										{element.items.map((item, j) => {
											return (
												<div
													className={
														'mlg_a-item' +
														(element.items[0].id === item.id ? ' active' : '')
													}
													key={j}>
													<Accordion.Toggle
														as="div"
														className="mlg_a-header"
														eventKey={item.id}>
														<span
															onClick={() =>
																setImage({
																	src: item.image.src,
																	alt: item.image.alt,
																})
															}>
															{j + 1}
														</span>
														<span
															onClick={() =>
																setImage({
																	src: item.image.src,
																	alt: item.image.alt,
																})
															}>
															{item.title}
														</span>
													</Accordion.Toggle>
													<Accordion.Collapse className="mlg_a-content" eventKey={item.id}>
														<div>
															<img
																src={imagePath(`./${item.image.src}`)}
																alt={item.image.alt}
															/>
															<p>{item.description}</p>
														</div>
													</Accordion.Collapse>
												</div>
											);
										})}
									</Accordion>
								</Tab.Pane>
							);
						})}
					</Tab.Content>
				</Tab.Container>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [image]);

	const accorditionImage = React.useMemo(() => {
		return image.src === 'Ergebnisse-Screen-Screen.png' ? (
			<div className="mlg_outer-div">
				<div>
					<img id="mlg_accordion-img-preview" src={imagePath(`./frame.png`)} alt="" />
					<div className="mlg_scroll-div">
						<img src={imagePath(`./${image.src}`)} alt="" />
					</div>
				</div>
			</div>
		) : (
			<img id="mlg_accordion-img-preview" src={imagePath(`./${image.src}`)} alt={image.alt} />
		);
	}, [image]);

	const columns = React.useMemo(() => {
		return (
			<>
				<Col xs={12} md={6} id="soutions-tabs-col">
					{tabs}
				</Col>

				<Col xs={12} md={6} id="solutions-last-col">
					{accorditionImage}
				</Col>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [image]);

	return (
		<section id="wie-funktioniert-THNG" className="mlg_section mlg_solutions">
			<Container>
				<Row className="mlg_solutions-small">
					<Col xs={12}>
						<div className="mlg_heading mlg_h-small">
							<h2 dangerouslySetInnerHTML={{ __html: data.title }} />
							<p dangerouslySetInnerHTML={{ __html: data.subtitle }} />
						</div>
					</Col>
				</Row>
				<Row>
					<Col xl={4} className="mlg_solutions-large">
						<div className="mlg_heading mlg_h-small">
							<h2 dangerouslySetInnerHTML={{ __html: data.title }} />
							<p dangerouslySetInnerHTML={{ __html: data.subtitle }} />
						</div>

						<img src={imagePath('./' + data.image.src)} alt={data.image.alt} className="mlg_img-left" />
					</Col>
					<Col xl={1} className="mlg_solutions-large" />
					{columns}
				</Row>
			</Container>
		</section>
	);
}
