import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Components
import ServiceItem from './service-item';

export default function Services(data) {
	return (
		<section id="vorteile" className="mlg_section mlg_services mb-6rem">
			<Container>
				<Row>
					<Col xs={12}>
						<div className="mlg_heading mb-4rem mb-sm-36px mb-xs-36px">
							<h2 style={{ fontSize: '50px', lineHeight: '65px' }}>
								Unser Können – <br id="service-header-br" />
								Ihr Nutzen
							</h2>
						</div>
					</Col>
				</Row>

				<Row>
					{data.services.map((service, key) => {
						return (
							<Col lg={4} md={6} sm={12} key={key}>
								<ServiceItem {...service} />
							</Col>
						);
					})}
				</Row>
			</Container>
		</section>
	);
}
